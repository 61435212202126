import { onMount } from 'solid-js';
import BgSplashBanner from '~/assets/backgrounds/tempSplashBanner.png';
import { LET_US_GUIDE_YOUR_HOME, BANNER_TITLE } from '~/assets/strings';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { PropertyForYou } from '~/pages/home/PropertyForYou';
import { UnitParams } from '~/types/Unit';
import { GetCompanyUnitsListingUseCase } from '~/use-cases/company-unit-listing/getCompanyListingUseCase';

export default function Index() {
  const { t } = useLocalization();
  const parmas: UnitParams = {
    Page: 1,
    PageSize: 6,
  };

  const { execute } = useUseCase(GetCompanyUnitsListingUseCase);
  onMount(() => {
    execute(parmas);
  });

  return (
    <div class=" w-full">
      <div
        class="relative flex h-[600px] w-full justify-center bg-cover bg-center bg-no-repeat"
        style={{ 'background-image': `url(${BgSplashBanner})` }}>
        <div class="flex w-max-container-width flex-col items-center  pt-[135px]">
          <div class="text-white">{t(LET_US_GUIDE_YOUR_HOME)}</div>
          <div class="w-[900px] text-center text-[70px] font-semibold text-white">{t(BANNER_TITLE)}</div>
        </div>
      </div>

      <PropertyForYou />
    </div>
  );
}
